<template>
  <div class="content-box">
    <div class="table-list">
      <el-table :data="tableData" style="width: 100%" :max-height="tableMaxheight" :header-cell-style="headerCellStyle" border
        v-loading="loading">
        <el-table-column prop="dictValue" align="center" label="检测类型" width="250">
        </el-table-column>
        <el-table-column prop="dictKey" align="center" label="code" width="150">
        </el-table-column>
        <el-table-column prop="dictDesc" align="center" label="介绍说明跳转地址">
        </el-table-column>
        <el-table-column prop="modifiedBy" align="center" label="操作人" width="250">
        </el-table-column>
        <el-table-column prop="modifiedTime" align="center" label="最后操作时间">
        </el-table-column>
        <el-table-column label="操作" width="100" align="center">
            <template #default="{ row }">
                <el-button type="text" @click="handleEdit(row)">编辑</el-button>
            </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="600px" :before-close="dialogClose" :close-on-click-modal="false">
        <div class="edit-box">
            <el-form label-position="left" label-suffix=":" label-width="90px" :model="formData" :rules="formRules" ref="validateForm">
                <el-form-item label="检测类型">
                    {{formData.dictValue}}
                </el-form-item>
                <el-form-item label="跳转地址" prop="dictDesc">
                    <el-input v-model="formData.dictDesc"></el-input>
                </el-form-item>
            </el-form>
            
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogClose">取消</el-button>
                <el-button :loading="saveLoading" type="primary" @click="dialogConfirm">提交</el-button>
            </div>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import { Watch, Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";
import UploadImg from "@/components/uploadImg.vue";

import config from "@/config/default";

import { getNavList as getNavListApi, updateDict } from "@/api/mini";

import { cloneDeep } from "lodash";
import { urlReg } from "@/utils/regular";

@Component({
    components: {
        UploadImg
    }
})
export default class Mini extends Mixins(loading, tablePagination, Resize) {
    tableData = [];
    itemTotal = 0;

    dialogVisible = false;
    dialogTitle = "新增小程序排版";
    saveLoading = false;

    formData = {
        id: "",
        dictValue: "",
        dictDesc: "",
    };

    formRules = {
        dictDesc: [{ required: true, message: "请输入跳转地址" }],
    }

    mounted() {
        this.windowResize(310)
        this.getData();
    }

    getData() {
        this.showLoading();
        getNavListApi("app_h5_compose").then((res) => {
            this.tableData = res.data || [];
        }).finally(() => {
            this.hideLoading();
        })
    }

  handleEdit(row = {}) {
    this.dialogVisible = true;
    this.$nextTick(() => {
        Object.assign(this.formData, row);
    })
  }

  dialogConfirm() {
    this.$refs['validateForm']?.validate().then(() => {
        const params = { ...this.formData }
        this.saveLoading = true;
        updateDict(params).then((res) => {
            this.dialogClose();
            this.getData();
        }).finally(() => {
            this.saveLoading = false;
        })
    })
  }


    dialogClose() {
        this.$nextTick(() => {
            this.dialogVisible = false;
            this.saveLoading = false;
        })
    }
}

</script>
<style lang="scss" scoped>
@import "@/styles/app";

.content-box {
  background: #edf0f9;
}

.content-header {
  height: 80px;
  background: #ffffff;
  border-bottom: 1px solid #dfe2e4;
  border-left: 1px solid #dfe2e4;
  border-right: 1px solid #dfe2e4;
  display: flex;
  align-items: center;
}

.input {
  width: 240px;
  height: 40px;
  background: #ffffff;
  border-radius: 2px;
  margin-left: 12px;
}
.cotent-btn {
  margin-left: 12px;
  border: 1px solid #0054ff;
}

.cotent-btn-big {
  margin-left: 40px;
  width: 300px;
  border: 1px dashed #0054ff;
}

.table-list {
  margin-top: 20px;
  background: #ffffff;
  border-radius: 8px;
  padding: 40px 40px;
  overflow: hidden;
  box-sizing: border-box;
  min-height: calc(100% - 50px);
}

.dialogInput {
    width: 260px
}

.dialog-footer{
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.store-saas-name{
  color: $primary;
  margin-left: 5px;
}
.tag {
  margin-right: 10px;
  height: auto;
  max-width: 100%;
  line-height: 20px;
  padding: 6px 10px;
  span{
    white-space: normal;
  }
}
.disable-btn {
  color: #30a16b;
}
.enable-btn {
  color: #ff6700;
}

.tip{
    font-size: 12px;
    color: #80858C;
}
:deep(.el-input__inner) {
    text-align: left;
}



</style>